<template>
  <div>
    <GameHistoryWidgets :widgets-data="[]" />
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-md-3 mb-2">
          <h6>
            {{ `${$t('fields.result')} (${filteredList.length})` }}
          </h6>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group>
            <b-form-input
              v-model="filterPlayerUsername"
              type="search"
              :placeholder="`${$t('fields.search')}... (${$t(
                'user.username'
              )})`"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group class="ml-md-2">
            <b-form-input
              v-model="filterRoomId"
              type="search"
              :placeholder="`${$t('fields.search')}... (${$t(
                'games.room_id'
              )})`"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <FilterAgentsDropdown @update="(val) => filterAgent = val" />
        </div>
        <!-- </label> -->
      </div>
      <!-- Table -->
      <b-table
        :items="filteredList"
        :fields="fields"
        :busy="isFetchingGameHistory"
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ `#${data.value}` }}
        </template>
        <template #cell(agentName)="data">
          <template v-if="
              data.item.agentCommissionDetail &&
              data.item.agentCommissionDetail.length > 0
            ">
            <span
              v-for="(agent, agentI) in data.item.agentCommissionDetail"
              :key="agentI"
            >
              <b-badge :id="`tooltip-${data.index}-${agentI}`">
                {{ agent.agentUsername }}
              </b-badge>
              <b-tooltip :target="`tooltip-${data.index}-${agentI}`">
                <b-link :to="`/agents/detail/${agent.agentId}`">
                  {{ agent.agentUsername }}
                </b-link>
                <p class="p-0 m-0">
                  <small class="mr-2">{{ $t('agent.commission') }}:</small>
                  {{ agent.commissionFee }}
                </p>
              </b-tooltip>
            </span>
          </template>
          <span v-else>
            {{ $t('agent.no_agent') }}
          </span>
        </template>
        <template #cell(masterName)="data">
          {{ data.value || 'Coming..'}}
        </template>
        <template #cell(transactionNo)="data">
          {{ data.value || 'Coming..'}}
        </template>
        <template #cell(gameNo)="data">
          {{ data.value || 'Coming..'}}
        </template>
        <template #cell(winAmount)="data">
          <span v-if="+data.value > 0">
            {{ data.value | currency }}
          </span>
          <span v-else>
            {{ $t('bots.name') }}
          </span>
        </template>
        <template #cell(type)="data">
          <b-badge variant="light">
            {{data.value || 'หวย' }}
          </b-badge>
        </template>
        <template #cell(gameId)="data">
          <b-button
            v-if="data.value"
            size="sm"
            variant="outline-info"
          >
            ดูโพย
          </b-button>
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(roomStatus)="data">
          <b-badge
            v-if="data.value === 'AVAILABLE'"
            variant="success"
          >
            {{'แทง'}}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
                        $t('messages.nothing_here')
                        }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="totalRows" @update="(val) => page = val"/>
    </b-card>
  </div>
</template>

<script>
// import dayjs from 'dayjs'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LottoGamesHistory',
  components: {
    GameHistoryWidgets: () => import('../history-widgets'),
    FilterAgentsDropdown: () => import('../../agents/filter-agents-dropdown'),
  },
  props: {
    gameSlug: {
      type: [String, Number],
      required: true,
      default: '',
    },
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      limit: 50,
      page: 1,
      filterAgent: '',
      filterRoomId: '',
      filterPlayerUsername: '',
      fields: [
        {
          key: 'id',
          label: '#',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agentName',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'masterName',
          label: 'ชื่อมาสเตอร์',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'transactionNo',
          label: 'เลขที่ Run ไปเรื่อยๆ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'gameNo',
          label: 'เลขรอบหวยที่ออก',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'bet',
          label: `${this.$t('games.bet_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'winAmount',
          label: `${this.$t('games.win_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'gameId',
          label: 'ดูโพย',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'player',
          label: 'ผู้เล่น',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'roomStatus',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingGameHistory: (state) => state.game.isFetchingGameScore,
    }),
    ...mapGetters(['']),
    filteredList() {
      return [
        {
          id: '423423',
          createdAt: new Date(),
          agentName: 'Agent123',
          masterName: 'Master',
          transactionNo: '4324',
          gameNo: '37377373',
          bet: 100,
          winAmount: 0,
          gameId: '12',
          player: 'User 88',
          roomStatus: 'AVAILABLE',
        },
      ]
    },
    totalRows() {
      return this.filteredList.length || 0
    },
    widgets() {
      const { totalCount, allBet, drinkWater } = this.gameScore
      return [
        {
          icon: 'activity',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: totalCount,
        },
        {
          icon: 'dollar-sign',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: allBet,
        },
        {
          icon: 'coffee',
          name: `${this.$t('pages.mini_games.total_fee')}`,
          value: drinkWater,
        },
      ]
    },
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    gameSlug(val) {
      if (val) {
        this.fetchData()
      }
    },
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterAgent(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterRoomId() {
      this.fetchDataByRoomId()
    },
    filterPlayerUsername() {
      this.fetchData()
    },
  },
  methods: {
    fetchData() {
      //   const date = dayjs(this.selectedDate || new Date()).format('YYYY-MM-DD')
      //   this.fetchGameScore({
      //     slug: this.gameSlug,
      //     date,
      //     page: this.page,
      //     limit: this.limit,
      //     agentUsername: this.filterAgent,
      //     roomId: this.filterRoomId,
      //     username: this.filterPlayerUsername,
      //   })
    },
    fetchDataByRoomId() {
      //   if (this.filterRoomId.length > 2) {
      //     this.fetchGameScore({
      //       slug: this.gameSlug,
      //       date: '',
      //       page: this.page,
      //       limit: this.limit,
      //       roomId: this.filterRoomId,
      //       username: this.filterPlayerUsername,
      //     })
      //   } else if (this.filterRoomId.length < 1) {
      //     this.fetchData()
      //   }
    },
  },
}
</script>
